// Generated by Framer (21ec140)

import { addFonts, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["VRM9njgdH"];

const serializationHash = "framer-GOIPg"

const variantClassNames = {VRM9njgdH: "framer-v-2i27hw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "VRM9njgdH", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 281.5, intrinsicWidth: 281.5, pixelHeight: 181, pixelWidth: 563, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/ONRFp81IqRR0o3IwGUvLKe3o.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/ONRFp81IqRR0o3IwGUvLKe3o.png?scale-down-to=512 512w,https://framerusercontent.com/images/ONRFp81IqRR0o3IwGUvLKe3o.png 563w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-2i27hw", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"VRM9njgdH"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-GOIPg.framer-11orpfs, .framer-GOIPg .framer-11orpfs { display: block; }", ".framer-GOIPg.framer-2i27hw { height: 176px; overflow: visible; position: relative; width: 176px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 176
 * @framerIntrinsicWidth 176
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerrUzksxw7N: React.ComponentType<Props> = withCSS(Component, css, "framer-GOIPg") as typeof Component;
export default FramerrUzksxw7N;

FramerrUzksxw7N.displayName = "Logo - Primary";

FramerrUzksxw7N.defaultProps = {height: 176, width: 176};

addFonts(FramerrUzksxw7N, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})